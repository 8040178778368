function NotifyIt(array)
{
    this.message = array.message
    this.status = array.status || null
    this.time = array.duration*1000 || 0

    this.ntfCheckForCurrentStructure = function(current)
    {
        // If there is an active instance, remove it.
        if (current) {
            this.ntfRemoveCurrentStructure(current)
        }
    }

    this.ntfBuildStructure = function()
    {
        // Creating container.
        this.ntfBoxContainer = document.createElement('div')
        this.ntfBoxContainer.classList.add('ntf-box-container')

        // Creating box.
        this.ntfBox = document.createElement('div')
        this.ntfBox.classList.add('ntf-box')

        // Creating message container.
        this.ntfMessage = document.createElement('span')
        this.ntfMessage.classList.add(
            'ntf-message'
        )

        // Creating progress bar.
        this.ntfProgress = document.createElement('div')
        this.ntfProgress.classList.add(
            'ntf-progress'
        )

        this.ntfProgressBar = document.createElement('div')
        this.ntfProgressBar.classList.add(
            'ntf-progress-bar'
        )

        if (this.status) {
            this.ntfMessage.classList.add(
                'ntf-message-'+this.status
            )
        }

        // Appending new instance to the body.
        this.ntfAppendStructure()
    }

    this.ntfAppendStructure = function()
    {
        // Appending message to its container.
        this.ntfMessage.appendChild(document.createTextNode(this.message))

        // Appending message container to the box.
        this.ntfBox.appendChild(this.ntfMessage)

        // Appending progress bar to the box.
        if (this.time > 0) {
            this.ntfProgress.appendChild(this.ntfProgressBar)
            this.ntfBox.appendChild(this.ntfProgress)
        }

        // Appending box to the container.
        this.ntfBoxContainer.appendChild(this.ntfBox)

        // Appending container to the body.
        document.body.appendChild(this.ntfBoxContainer)

        // Showing up new instance.
        this.ntfShowUpStructure()
    }

    this.ntfShowUpStructure = function()
    {
        var self = this

        setTimeout(function() {
            // Setting new instance as the current one after showing it up.
            self.ntfBoxContainer.classList.add(
                'ntf-box-container-current',
                'ntf-visible'
            )

            // Attatching 'click' event to the new instance.
            self.ntfBoxContainer.addEventListener('click', function() {
                self.ntfRemoveCurrentStructure(this)
            })

            if (self.time > 0) {
                // Fire progress bar.
                self.fireProgressBar()

                setTimeout(function() {
                    self.ntfRemoveCurrentStructure(self.ntfBoxContainer)
                }, self.time)
            }

            // If user presses Escape key.
            function isEscapePressed(e)
            {
                document.removeEventListener('keyup', isEscapePressed)

                if (e.key === 'Escape') {
                    self.ntfRemoveCurrentStructure(self.ntfBoxContainer)
                }
            }
            document.addEventListener('keyup', isEscapePressed)
        },
        50)
    }

    this.ntfRemoveCurrentStructure = function(element)
    {
        element.classList.remove(
            'ntf-box-container-current',
            'ntf-visible'
        )

        setTimeout(function() {
            element.remove()
        }, this.getElementTransitionDuration(element))
    }

    this.getElementTransitionDuration = function(element)
    {
        var transitionDuration = getComputedStyle(element)['transitionDuration']
        var transitionDurationValue = parseFloat(transitionDuration, 10)

        // Converting to milliseconds.
        if (! transitionDuration.match(/ms/)) {
            transitionDurationValue *= 1000
        }

        // Add extra time to prevent loading issues.
        transitionDurationValue += 50

        return transitionDurationValue
    }

    this.fireProgressBar = function()
    {
        var self = this

        var start = new Date()
        var maxTime = self.time
        var timeoutVal = Math.floor(maxTime/100)

        animateUpdate()

        function updateProgress(percentage) {
            self.ntfProgressBar.style.width = percentage + "%"
        }

        function animateUpdate() {
            var now = new Date()
            var timeDiff = now.getTime() - start.getTime()
            var perc = Math.round((timeDiff / maxTime) * 100)

            // console.log(perc)

            if (perc <= 100) {
                updateProgress(perc)
                setTimeout(animateUpdate, timeoutVal)
            }
        }
    }

    // Checking if there is an active instance of notifyit.
    this.ntfCheckForCurrentStructure(
        document.querySelector('.ntf-box-container-current')
    )

    // Building a new instance.
    this.ntfBuildStructure()
}

export default NotifyIt
