import React, { Fragment } from 'react'
import $ from 'jquery'
import './App.scss'
import 'remixicon/fonts/remixicon.css'
import logoAlternativo from './assets/img/logo-alternativo.svg'
import NotifyIt from './notifyit'

function App() {
  const submitContactForm = e => {
    e.preventDefault()

    var $this = $(e.target)
    var $submitBtn = $this.find('button[type=submit]')
    var submitBtnLabel = $submitBtn.html()

    $.ajax({
      type: $this.attr('method'),
      url: $this.attr('action'),
      data: new FormData($this[0]),
      cache: false,
      processContent: false,
      contentType: false,
      processData: false,
      dataType: false,
      beforeSend: function () {
        $submitBtn.prop('disabled', true)
        $submitBtn.html('<i class="ri ri-loader-2-line ri--spin"></i>')
      },
      success: function (res) {
        var response = JSON.parse(res)

        new NotifyIt({
          // Message to display.
          message: response[1],
          // Status of the displayed message.
          status: response[0] ? 'success' : 'error',
          // Duration of the alert. 0 to keep it.
          duration: 5,
        })

        if (response[0]) {
          $this[0].reset()
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        new NotifyIt({
          // Message to display.
          message: errorThrown,
          // Status of the displayed message.
          status: 'error',
          // Duration of the alert. 0 to keep it.
          duration: 5,
        })
      },
      complete: function () {
        $submitBtn.prop('disabled', false)
        $submitBtn.html(submitBtnLabel)
      },
    })
  }

  return (
    <Fragment>
      <div className='container'>
        <main className='main'>
          <img
            src={logoAlternativo}
            alt='Guilherme Dorta Advogados'
            className='logo'
          />
          <div className='description'>
            <p>
              O escritório Guilherme Dorta Advogados busca atender as demandas
              dos seus clientes de modo prático, eficaz e na busca das melhores
              soluções para o caso em concreto.
            </p>
            <p>
              Atuamos na área de direito civil e empresarial, além de contar com
              ampla rede de correspondentes em todo o país.
            </p>
            <p>
              A excelência e a sofisticação dos serviços, amparados pela
              expertise e agilidade dos seus profissionais.
            </p>
          </div>
        </main>
        <aside className='aside'>
          <form
            action='/submit.php'
            method='POST'
            className='contact-form'
            onSubmit={e => submitContactForm(e)}
          >
            <input
              type='text'
              name='name'
              placeholder='Nome:'
              autoComplete='off'
              className='form-input sairobo'
            />
            <input
              type='text'
              name='email'
              placeholder='E-mail:'
              autoComplete='off'
              className='form-input sairobo'
            />
            <input
              type='text'
              name='subject'
              placeholder='Assunto:'
              autoComplete='off'
              className='form-input sairobo'
            />
            <textarea
              name='message'
              placeholder='Mensagem...'
              autoComplete='off'
              className='form-textarea sairobo'
            ></textarea>

            <input
              type='text'
              name='gdadvname'
              placeholder='Nome:'
              autoComplete='off'
              className='form-input'
            />
            <input
              type='text'
              name='gdadvemail'
              placeholder='E-mail:'
              autoComplete='off'
              className='form-input'
            />
            {/* <input type="text" name="gdadvsubject" placeholder="Assunto:" autoComplete="off" className="form-input" /> */}
            <textarea
              name='gdadvmessage'
              placeholder='Mensagem...'
              autoComplete='off'
              className='form-textarea'
            ></textarea>

            <button type='submit' className='form-submit-btn'>
              Enviar mensagem
            </button>
          </form>
        </aside>
        <footer className='footer'>
          <div className='contact-links'>
            <a
              href='mailto:contato@dortaadv.com.br'
              rel='noopener noreferrer'
              target='_blank'
              className='contact-link'
            >
              <i className='ri ri-mail-line'></i>
              contato@dortaadv.com.br
            </a>
            <a
              href='https://wa.me/551148581197'
              rel='noopener noreferrer'
              target='_blank'
              className='contact-link'
            >
              <i className='ri ri-whatsapp-line'></i>
              +55 (11) 4858-1197
            </a>
            <a
              href='https://www.google.com/maps/place/Av.+Marcos+Penteado+de+Ulhoa+Rodrigues,+939+-+Tambor%C3%A9,+Barueri+-+SP,+06460-040/@-23.5058199,-46.8393553,17z/data=!3m1!4b1!4m6!3m5!1s0x94cf01f729d35939:0x637f60e2deefe1ce!8m2!3d-23.5058248!4d-46.8371666!16s%2Fg%2F11crr99vp0'
              rel='noopener noreferrer'
              target='_blank'
              className='contact-link'
            >
              <i className='ri ri-map-pin-line'></i>
              Av. Marcos Penteado de Ulhoa Rodrigues, 939 - 8º andar -
              Alphaville - Barueri/SP
            </a>
          </div>
        </footer>
      </div>
    </Fragment>
  )
}

export default App
